.topDecks{
    display: inline-flexbox;
    text-align: center;
}
.deck{
    border: 1px #7b7575 solid;
    background-color: #e6e6e6;
    display: inline-block;
    margin-bottom: 1%;
    margin-left: 3%;
    margin-right: 3%;
    padding: 6px;
}
.stats{
    margin: 0px;
    padding: 0px;
    font-size: .8em;
}
.link{
    height: 16px;
    width: 16px;
}

.link:hover{
    cursor: pointer;
}