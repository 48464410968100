.battle{
    display: inline-flex;
    padding: 8px;
    border-bottom: 1px #3a3737 solid;
    width: 100%;
    max-width: 100vw;

}
.leftPanel{
    flex-basis: 40%;
    padding-right: 16px;
}
.leftPanel:hover
{
    background-color: #e6e6e6;
    cursor: pointer;
}


.centerPanel{
    flex-basis: 20%;
}
.centerPanel h1{
    margin-top: 50%;
    margin-bottom: 20%;
}

.rightPanel{
    flex-basis: 40%;
    padding-left: 16px;
}
.rightPanel:hover
{
    background-color: #e6e6e6;
    cursor: pointer;
}