
.chest{
  display: inline-flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-left: 8px;
  margin-right: 8px;
  margin-top: 0px;
  margin-bottom: 0px;
  max-width: 5%;
  -webkit-text-stroke: .25px black;
}
.chest strong{
  margin: 0px;
  flex-basis: 50%;
  font-size:1vw;
}
.chest p{ 
  flex-basis: 50%;
  font-weight: 600;
  line-break: strict;
  font-size: 1vw;
}
.chest img{
  margin: 0px;
  flex-basis: 100%;
  
max-height: 64px;    
max-width:  64px;    
  min-width: 32px;
  min-height: 32px;
}