.form-signin {
  margin: auto;
  text-align: center;
  overflow-wrap: break-word;
  background-color:'#d3d3d3';
  min-width: min-content;
  max-width: 80vw;
}
.form-signin .checkbox {
  font-weight: 400;
}
.form-signin .form-control {
  position: relative;
  box-sizing: border-box;
  height: auto;
  padding: 10px;
    font-size: 16px;
    background-color:'#d3d3d3';
}
.form-signin .form-control:focus {
  z-index: 2;
}
.form-signin input[type="email"] {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.form-signin input[type="password"] {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.home-search{
  margin: 32px;
  margin-top: 0px;
  z-index: 1;
  text-align: center;
  width: auto;
  font-weight: 700;
  color:white;
  -webkit-text-stroke: .25px black;
} 